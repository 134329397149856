<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
  >
    <path
      d="M6.82945 3.99961H19.9995C21.1095 3.99961 21.9995 4.88961 21.9995 5.99961V17.9996C21.9995 18.3396 21.9195 18.6596 21.7695 18.9396L19.9995 17.1696V11.9996H14.8295L10.8295 7.99961H19.9995V5.99961H8.82945L6.82945 3.99961ZM20.4895 23.3096L17.1695 19.9996H3.99945C2.88945 19.9996 1.99945 19.1096 1.99945 17.9996L2.00945 5.99961C2.00945 5.65961 2.08945 5.33961 2.23945 5.06961L0.689453 3.50961L2.09945 2.09961L21.8995 21.8996L20.4895 23.3096ZM3.99945 6.82961V7.99961H5.16945L3.99945 6.82961ZM15.1695 17.9996L9.16945 11.9996H3.99945V17.9996H15.1695Z"
      fill="#16205E"
    />
  </svg>
</template>

<style scoped>
.svg-icon path {
  fill: var(--default-font-color);
}
</style>
