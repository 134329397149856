<template>
  <div class="content">
    <div class="title-container">
      <BaseH1 :text="title" :short="true" />
      <TextDescription :text="description" />
    </div>
    <PremiumPlansComparison />
    <PremiumGroupAdvantages />
    <ButtonCta
      :label="'Zobacz plany'"
      :to="{ name: ROUTE_ACCOUNT_PREMIUM_NAME }"
    />
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";

import PremiumGroupAdvantages from "@/components/Premium/PremiumGroupAdvantages.vue";
import PremiumPlansComparison from "@/components/Premium/PremiumPlansComparison.vue";
import TextDescription from "@/components/UI/TextDescription.vue";
import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import ButtonCta from "@/components/UI/Base/ButtonCta.vue";
import { ROUTE_ACCOUNT_PREMIUM_NAME } from "@/router/constants";

export default {
  components: {
    PremiumGroupAdvantages,
    PremiumPlansComparison,
    TextDescription,
    BaseH1,
    ButtonCta,
  },

  setup() {
    const state = reactive({
      title: "O jejku!",
      description: "Aby udostępnić tą opcję, musisz posiadać konto Premium",
    });

    return {
      ...toRefs(state),
      ROUTE_ACCOUNT_PREMIUM_NAME,
    };
  },
};
</script>

<style scoped>
.content {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 60px;
}
.title-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 30px;
}

@media (max-width: 1200px) {
  .content {
    row-gap: 60px;
  }
  .title-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    row-gap: 30px;
  }
}
</style>
