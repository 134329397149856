<template>
  <BaseGroupAdvantages :items="advantages" />
</template>

<script>
import BaseGroupAdvantages from "@/components/UI/Base/BaseGroupAdvantages.vue";

// Icons
import SvgIconsSavings24 from "@/components/Svg/Icons/SvgIconsSavings24.vue";
import SvgIconsCreditCard24 from "@/components/Svg/Icons/SvgIconsCreditCard24.vue";
import { markRaw } from "vue";

export default {
  components: { BaseGroupAdvantages },

  setup() {
    const advantages = [
      {
        slug: "one_time_payment",
        title: "Płatność jednorazowa",
        text: "Bez subskrypcji i ukrytych kosztów",
        iconComponent: markRaw(SvgIconsCreditCard24),
      },
      {
        slug: "zero_comission",
        title: "Zero prowizji",
        text: "Za świadczone usługi zapłacisz bezpośrednio nianię",
        iconComponent: markRaw(SvgIconsSavings24),
      },
    ];

    return {
      advantages,
    };
  },
};
</script>
