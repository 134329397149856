<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 7.99983H17V6.20983C17 3.59983 15.09 1.26983 12.49 1.01983C9.51 0.739834 7 3.07983 7 5.99983V7.99983H4V21.9998H20V7.99983ZM12 16.9998C10.9 16.9998 10 16.0998 10 14.9998C10 13.8998 10.9 12.9998 12 12.9998C13.1 12.9998 14 13.8998 14 14.9998C14 16.0998 13.1 16.9998 12 16.9998ZM9 7.99983V5.99983C9 4.33983 10.34 2.99983 12 2.99983C13.66 2.99983 15 4.33983 15 5.99983V7.99983H9Z"
      fill="#848AAA"
    />
  </svg>
</template>
