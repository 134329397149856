<template>
  <div class="item">
    <component :is="iconComponent" class="icon item__icon" />
    <div class="content item__content">
      <h4 class="title content__title">{{ title }}</h4>
      <p class="content content__text">{{ text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    iconComponent: Object,
    title: String,
    text: String,
  },
};
</script>

<style scoped>
.item {
  display: flex;
  column-gap: 20px;
}
.item__content {
  width: 260px;
  display: flex;
  flex-flow: column;
  row-gap: 5px;
}
.content__title {
  font: var(--h4--font);
}
.content__text {
  font: var(--font-small);
  opacity: 0.6;
}
</style>
