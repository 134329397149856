<template>
  <div class="container-advantages">
    <BaseGroupAdvantagesItem
      v-for="item in items"
      :key="item.slug"
      :title="item.title"
      :text="item.text"
      :icon-component="item.iconComponent"
    />
  </div>
</template>

<script>
import BaseGroupAdvantagesItem from "@/components/UI/Base/BaseGroupAdvantagesItem.vue";

export default {
  components: { BaseGroupAdvantagesItem },

  props: {
    items: {
      type: Array,
    },
  },
};
</script>

<style scoped>
.container-advantages {
  width: 100%;

  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  gap: 40px;
}

@media (max-width: 1200px) {
  .container-advantages {
    width: 100%;

    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    column-gap: 13px;
    row-gap: 44px;
  }
}
</style>
