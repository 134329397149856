<template>
  <div class="container">
    <div class="row row-headers">
      <div class="col col-2 header-free">Darmowo</div>
      <div class="col col-3 header-premium">Premium</div>
    </div>
    <div class="content">
      <div class="row row-empty">
        <div class="col col-1"></div>
        <div class="col col-2 col-free"></div>
        <div class="col col-3 col-premium"></div>
      </div>

      <div
        v-for="feature in features"
        :key="feature.slug"
        class="row row-feature"
      >
        <div class="col col-1">
          <p class="description">{{ feature.description }}</p>
        </div>
        <div class="col col-2 col-free">
          <SvgIconsDone24 v-if="feature.isFree" />
          <SvgIconsLock24 v-else />
        </div>
        <div class="col col-3 col-premium">
          <SvgIconsDone24 />
        </div>
      </div>

      <div class="row row-empty">
        <div class="col col-1"></div>
        <div class="col col-2 col-free"></div>
        <div class="col col-3 col-premium"></div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIconsDone24 from "@/components/Svg/Icons/SvgIconsDone24.vue";
import SvgIconsLock24 from "@/components/Svg/Icons/SvgIconsLock24.vue";

export default {
  components: { SvgIconsDone24, SvgIconsLock24 },

  setup() {
    const features = [
      {
        slug: "add_offers",
        description: "Dodanie ogłoszenia",
        isFree: true,
      },
      {
        slug: "see_contractors",
        description: "Nieograniczony przegląd profili niań",
        isFree: true,
      },
      {
        slug: "contact_contractors",
        description: "Kontakt z nianiami",
        isFree: false,
      },
      {
        slug: "sms_notification_contractors",
        description: "SMS-powiadomenie o Twoim ogłoszeniu nianiom w okolicea",
        isFree: false,
      },
      {
        slug: "see_responses",
        description: "Przegląd zgłoszeń",
        isFree: false,
      },
    ];

    return {
      features,
    };
  },
};
</script>

<style scoped>
.container {
  width: var(--layout-view-block-11--column-width);
  display: flex;
  flex-flow: column;
}
.content {
  display: flex;
  flex-flow: wrap;
  background: var(--container-premium-plans-comparison--bg-color);
  border-radius: var(--container-premium-plans-comparison--border-radius);
  overflow: hidden;
}
.row {
  display: flex;
  width: 100%;
}
.row-headers {
  height: 49px;
  justify-content: flex-end;
  font: var(--font-small);
  font-weight: var(--h2--font-weight);
}
.row-empty {
  height: 13px;
}
.col {
  display: flex;
  justify-content: center;
  align-items: center;
}
.col-1 {
  width: 54%;
}
.col-2 {
  width: 23%;
}
.col-3 {
  width: 23%;
}
.col-premium {
  background-color: var(--container-premium-plans-comparison-premium--bg-color);
}
.description {
  width: 100%;
  padding: 13px 22px;
  font: var(--font-small);
}

@media (max-width: 1200px) {
  .row-headers {
    font-size: 14px;
    height: 35px;
  }
  .col-1 {
    width: 50%;
  }
  .col-2 {
    width: 25%;
  }
  .col-3 {
    width: 25%;
  }
  .description {
    padding: 10px 5px 10px 20px;
    font-size: 14px;
  }
}
</style>
